<script lang="ts">
  import {
    type JSONReferentielObject,
    type ResourceAndItsPath,
    type ReferentielInMenu,

    type ActivationName

  } from '../../lib/types/referentiels'
  import {
    applyFilters,
    buildReferentiel,
    getAllEndings
  } from '../utils/refUtils'
  import ReferentielNode from './ReferentielNode.svelte'
  import SearchBlock from './SearchBlock.svelte'
  import SideMenuApps from './SideMenuApps.svelte'
  import {
    referentiels,
    originalReferentiels,
    deepReferentielInMenuCopy
  } from '../stores/referentielsStore'
  import codeToLevelList from '../../json/codeToLevelList.json'
  import { onMount } from 'svelte'
  import { title } from 'process';
    import { info } from 'console';
  export let excludedReferentiels: ActivationName[] = []
  export let subset: JSONReferentielObject
  subset={}
  /**
   * Mise à jour des référentiels en tenant compte des filtres
   * La fonction est appelée lorsqu'est détecté l'événement `filters-change`
   * diffusé par le composant `Filtres` (et remonté à travers tous les composants parents
   * jusqu'à SideMenu) ou le composant `Chip` de la liste des puces de filtres
   */
  const updateRef = () => {
    let filteredReferentielItems: ResourceAndItsPath[] = []
    const results: ReferentielInMenu[] = []
    const copyOfOriginalReferentiel: ReferentielInMenu[] =
      deepReferentielInMenuCopy(originalReferentiels).filter((e) => {
        if (excludedReferentiels.includes(e.name)) {
          return false
        } else {
          return true
        }
      })
    copyOfOriginalReferentiel.forEach((item) => {
      if (item.searchable) {
        const all = getAllEndings(item.referentiel)
        const matchingItems: ResourceAndItsPath[] = applyFilters(all)
        filteredReferentielItems = [
          ...filteredReferentielItems,
          ...matchingItems
        ]
        let filteredReferentiel: JSONReferentielObject =
          buildReferentiel(matchingItems)
        // on ordonne les entrées dans la liste (suivant l'ordre de codeToLevelList.json)
        if (item.name === 'aleatoires') {
          for (const key of Object.keys(codeToLevelList).reverse()) {
            if (Object.keys(filteredReferentiel).includes(key)) {
              const keyToBeFirst = { [key]: null }
              filteredReferentiel = Object.assign(
                keyToBeFirst,
                filteredReferentiel
              )
            }
          }
        }
        const updatedItem: ReferentielInMenu = {
          title: item.title,
          name: item.name,
          searchable: item.searchable,
          referentiel: (item.referentiel = { ...filteredReferentiel })
        }
        results.push(updatedItem)
      } else {
        // /!\ TODO : doit-ordonner les référentiels non cherchable ? (item.referentiel)
        results.push({ ...item })
      }
    })
    $referentiels = [...results]
  }

  const buildHaystack = (
    refList: ReferentielInMenu[]
  ): ResourceAndItsPath[] => {
    let result: ResourceAndItsPath[] = []
    for (const item of refList) {
      if (item.searchable) {
        result = [...result, ...getAllEndings(item.referentiel)]
      }
    }
    return result
  }
  onMount(() => { updateRef() })
</script>

<aside
  class="flex w-full md:h-full md:min-h-full flex-col items-start pb-4 pt-0 md:pt-4 ml-0 md:mx-0 bg-aleatex-canvas-dark dark:bg-aleatexdark-canvas-dark"
>
      <SearchBlock
        class="w-full flex flex-col justify-start pt-0 sm:"
        resourcesSet={buildHaystack($referentiels)}
        on:filters-change={updateRef}
      />
      <div class="mt-4 w-full">
        <!-- Affichage de tous les référentiels -->
        <!-- Pour les CAN on enlève le 1er niveau de profondeur -->
        {#each $referentiels as item, i}        
          {#if item.title === 'Course aux nombres (aléatoirisée)'}          
            <ReferentielNode
              bind:subset={item.referentiel["Course aux nombres"]}
              indexBase={i + 1}
              levelTitle={item.title}
              nestedLevelCount={1}
              class="w-full px-4 text-[10px]"
              pathToThisNode={[]}
            />
          {:else}
            <ReferentielNode
              bind:subset={item.referentiel}
              indexBase={i + 1}
              levelTitle={item.title}
              nestedLevelCount={1}
              class="w-full px-4 text-[10px]"
              pathToThisNode={[]}
            />
          {/if}
        {/each}
        <!-- Bouton spécial pour les applications tierces -->
        <!-- <SideMenuApps class="text-start p-6 w-full" /> -->
      </div>
</aside>
