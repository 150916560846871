<script lang="ts">
  import { exercicesParams, moveExercice } from '../stores/generalStore'
  export let indice: number
  function monter () {
    exercicesParams.update((l) => {
      return moveExercice(l, indice, indice - 1)
    })
  }
</script>

<button on:click={monter} class={indice > 0 ? '' : 'hidden md:invisible'}>
  <i
    class="text-aleatex-action hover:text-aleatex-action-lightest dark:text-aleatexdark-action dark:hover:text-aleatexdark-action-lightest bx bx-up-arrow-circle"
  />
</button>
