<svg
   viewBox="0 0 30 30"
   id="svg5"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg"
   class={$$props.class}
   fill="currentColor" 
>
   <style>
    /* Note that the color of the text is set with the    *
     * fill property, the color property is for HTML only */
    .tex {
      font-weight:700;
      font-stretch:ultra-condensed;
      font-size:12px;
      line-height:1.25;
      font-family:inherit;
      -inkscape-font-specification:'League Gothic, Ultra-Condensed';
      text-align:end;
      text-anchor:end;
      stroke-width:0.264583;
    }
  </style>
  <text x="4" y="14"  transform="translate(3,-6) scale(1 2)" class="tex">C</text>
  <text x="14" y="14" transform="translate(-2.5,3.5) scale(1 1)" class="tex">A</text>
  <text x="24" y="14" transform="translate(-4.5,2) scale(1 1.2)" class="tex">N</text>   
</svg>
