<script lang="ts">	
	import { darkMode, isAccueilShown } from './stores/generalStore'
	import NavBarSubtitle from './header/NavBarSubtitle.svelte'
	import Footer from './Footer.svelte'	
	import Button from './forms/Button.svelte'	
    
	export let title: string = 'aleaTeX'
  	export let subtitle: string = 'De l\'aléatoire et du LaTeX'  	
	export let btnName: string = "Lancer aleaTeX";	
</script>
	
<main
	class="bg-aleatex-canvas dark:bg-aleatexdark-canvas {$darkMode.isActive
	? 'dark'
	: ''}"
>	  
	<nav class="p-4 bg-aleatex-canvas dark:bg-aleatexdark-canvas md:h-[120px]">
	<!-- container -->
	<div
		class="flex flex-row justify-between items-start w-full mx-auto md:space-x-6"
	>
		<div class="flex flex-col md:flex-row justify-start space-x-0 md:space-x-2">
		<!-- logo -->
		<div class="">
			<div
			on:click={() => window.location.href='/'}
			on:keydown={() => window.location.href='/'}
			role="link"
			tabindex="0"
			class=" relative inline-flex text-3xl md:text-6xl font-logo9 tracking-tighter font-black text-aleatex-struct dark:text-aleatexdark-struct "
			>
			{title}	  
			<div
				class="absolute -bottom-4 left-1 font-light text-sm text-aleatex-corpus-lightest dark:text-aleatexdark-corpus-lightest"
			>
				<span class="font-light font-sans mr-1 tracking-normal">par</span>
			<a
				href="/"				
				rel="noreferrer"
				class="font-extrabold font-logo9 tracking-tighter text-aleatex-action dark:text-aleatexdark-action hover:text-aleatex-action-lightest dark:hover:text-aleatexdark-action-lightest"
			>
			SL & CP<sup>2</sup>
			</a>
			</div>
			</div>
		</div>
		<div
		class="flex flex-row items-center space-x-4 pt-6 md:pt-0 md:inline-flex text-lg md:text-3xl xl:text-5xl font-logo9 tracking-tighter"
		>
		<div
			class="pl-0 md:pl-10 font-light text-aleatex-corpus-lightest dark:text-aleatexdark-corpus-lightest"
		>
			<i class='bx bx-chevrons-right translate-y-1'/>			  
		</div>
		<div
			class="pl-0 lg:pl-2 font-black text-aleatex-struct dark:text-aleatexdark-struct"
		>
			{subtitle}
		</div>
		</div>		
		</div>
		<div class="flex flex-row space-x-4 px-0 pt-2 md:px-4">
		<label
			class="swap swap-rotate text-aleatex-action hover:text-aleatex-action-lightest dark:text-aleatexdark-action dark:hover:text-aleatexdark-action-lightest"
		>
			<!-- this hidden checkbox controls the state -->
			<input
			id="hidden-checkbox-for-darkmode"
			type="checkbox"
			class="invisible"
			bind:checked={$darkMode.isActive}
			/>
			<!-- sun icon -->
			<div class="swap-on"><i class="bx bx-sm bx-sun" /></div>
			<!-- moon icon -->
			<div class="swap-off"><i class="bx bx-sm bx-moon" /></div>
		</label>
		<!--  -->
		<Button
			title=""
			icon="bx-x"
			class="text-3xl"
			on:click={() => {
				isAccueilShown.set(true)
				sessionStorage.isAccueilShown=true
				window.location.reload()
			}}
		/>
		</div>
	</div>
	</nav>
	  <section>
		<div class="relative h-96 w-full flex items-center justify-center">
		  <div class="absolute bg-green-100 rounded-full transform left-1/2 -translate-x-1/2 w-96 h-full"></div>
		  <div class="relative text-center space-y-6 flex flex-col items-center">
			<h2 class="text-6xl font-serif text-gray-700 z-50">
				Html &amp; LaTeX
			</h2>
			<div class="w-6 h-1 bg-gray-700 rounded"></div>
			<p class="text-gray-500 text-xl max-w-screen-sm">
				Des exercices à données aléatoires.
				<br>
				Un export LaTeX nativement aléatoire.
				<br>
				Avec une distribution LaTeX locale à jour<br> et un combo magique <i class='bx bx-wink-smile translate-y-1'/>
			</p>
			<div class="flex place-items-center space-x-6">
				<Button
				class="px-5 py-2 rounded-md"
				idLabel='btnProfCollege'
				on:click={() => {
					window.open('https://www.ctan.org/pkg/profcollege')
				}}
				title='ProfCollege'
				/>
				<Button
				class="px-5 py-2 rounded-md"
				idLabel='btnProfMaquette'
				on:click={() => {
					window.open('https://www.ctan.org/pkg/profmaquette')
				}}
				title='ProfMaquette'
				/>
			</div>
			<div class="flex place-items-center space-x-6">
				<Button
				class="text-3xl font-bold px-5 py-3 rounded-md"
				idLabel={btnName}
				on:click={() => {
					isAccueilShown.set(true)
					sessionStorage.isAccueilShown=true
					window.location.reload() 
				}}
				title={btnName}
				/>
			</div>
		  </div>
		</div>
	  </section>
	  <footer>
		<Footer />
	  </footer>
</main>

<style>
	footer {
	/* margin-top: auto; */
	/* position: absolute;
    bottom: 0; */
    position: fixed;
    bottom:0;
    width:100%;
	}
</style>
	