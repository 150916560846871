<script lang="ts">
  import {
    globalOptions,
    resultsByExercice,
    exercicesParams,
    changes
  } from '../stores/generalStore'
  import { afterUpdate, onMount, tick, onDestroy } from 'svelte'
  import seedrandom from 'seedrandom'
  import {
    prepareExerciceCliqueFigure,
    exerciceInteractif
  } from '../../lib/interactif/gestionInteractif'
  import { loadMathLive } from '../../modules/loaders'
  import {
    mathaleaFormatExercice,
    mathaleaHandleExerciceSimple,
    mathaleaHandleSup,
    mathaleaRenderDiv,
    mathaleaUpdateUrlFromExercicesParams
  } from '../../lib/aleatex'
  import HeaderExerciceVueProf from './HeaderExerciceVueProf.svelte'
  import Settings from './Settings.svelte'
  import { exercisesUuidRanking, uuidCount } from '../utils/counts'
  import Exercice from '../../exercices/ExerciceTs.js'
  import type { HeaderProps } from '../../lib/types/ui'
  export let exercice: Exercice
  export let indiceExercice: number
  export let indiceLastExercice: number
  export let isCorrectionVisible = false

  let divExercice: HTMLDivElement
  let divScore: HTMLDivElement
  let buttonScore: HTMLButtonElement
  let columnsCount = $exercicesParams[indiceExercice].cols || 1
  let isVisible = true
  let isContentVisible = true
  let isSettingsVisible = true
  let isInteractif = exercice.interactif
  const interactifReady = exercice.interactifReady
  let isExerciceChecked = false
  const id: string = $exercicesParams[indiceExercice]?.id
    ? exercice.id
      ? exercice.id.replace('.js', '').replace('.ts', '')
      : ''
    : ''
  const generateTitleAddendum = (): string => {
    const ranks = exercisesUuidRanking($exercicesParams)
    const counts = uuidCount($exercicesParams)
    if (
      $exercicesParams[indiceExercice] &&
      $exercicesParams[indiceExercice].uuid &&
      counts[$exercicesParams[indiceExercice].uuid] > 1
    ) {
      return '|' + ranks[indiceExercice]
    } else {
      return ''
    }
  }
  let headerProps: HeaderProps = {
    title: '',
    id,
    indiceExercice,
    indiceLastExercice,
    isInteractif,
    interactifReady,
    isSettingsVisible
  }

  $: {
    if (isContentVisible && isInteractif && buttonScore) initButtonScore()
    if ($globalOptions.v === 'eleve') {
      headerProps.settingsReady = false
      headerProps.isSortable = false
      headerProps.isDeletable = false
      headerProps.isHidable = false
      if ($globalOptions.setInteractive === '1') {
        setAllInteractif()
      } else if ($globalOptions.setInteractive === '0') {
        removeAllInteractif()
      }
      if (!$globalOptions.isSolutionAccessible) {
        headerProps.correctionReady = false
        headerProps.randomReady = false
      }
    } else {
      headerProps.settingsReady = true
      headerProps.isSortable = true
      headerProps.isDeletable = true
      headerProps.isHidable = true
    }
    headerProps.isInteractif = isInteractif
    headerProps.correctionExists = exercice.listeCorrections.length > 0
    headerProps.title = exercice.titre + generateTitleAddendum()
    headerProps.indiceExercice = indiceExercice
    headerProps.indiceLastExercice = $exercicesParams.length
    headerProps.isSettingsVisible = isSettingsVisible
    headerProps = headerProps
  }

  let numberOfAnswerFields: number = 0
  async function countMathField () {
    // IDs de la forme 'champTexteEx1Q0'
    const answerFields = document.querySelectorAll(
      `[id^='champTexteEx${indiceExercice}']`
    )
    numberOfAnswerFields = answerFields.length
  }

  // on détecte les changements dans la liste des exercices
  // afin de mettre à jour le titre
  const unsubscribeToChangesStore = changes.subscribe(() => {
    headerProps.title = exercice.titre + generateTitleAddendum()
  })

  onDestroy(() => {
    // Détruit l'objet exercice pour libérer la mémoire
    for (const prop of Object.keys(exercice)) {
      Reflect.deleteProperty(exercice, prop)
    }
    unsubscribeToChangesStore()
  })

  async function forceUpdate () {
    if (exercice == null) return
    exercice.numeroExercice = indiceExercice
    await adjustMathalea2dFiguresWidth()
  }

  onMount(async () => {
    document.addEventListener('newDataForAll', newData)
    document.addEventListener('setAllInteractif', setAllInteractif)
    document.addEventListener('removeAllInteractif', removeAllInteractif)
    document.addEventListener('updateAsyncEx', forceUpdate)
    await updateDisplay()
    await tick()
    await countMathField()
  })

  afterUpdate(async () => {
    if (exercice) {
      await tick()
      if (isInteractif) {
        await loadMathLive()
        if (exercice?.interactifType === 'cliqueFigure') {
          prepareExerciceCliqueFigure(exercice)
        }
        // Ne pas être noté sur un exercice dont on a déjà vu la correction
        if (
          window.localStorage != null &&
          exercice.id !== undefined &&
          exercice.seed !== undefined &&
          window.localStorage.getItem(`${exercice.id}|${exercice.seed}`) !=
            null &&
          isContentVisible
        ) {
          await newData()
        }
      }
      mathaleaRenderDiv(divExercice)
      if (!exercice.nbQuestionsModifiable &&
       !exercice.besoinFormulaireCaseACocher &&
       !exercice.besoinFormulaireNumerique &&
       !exercice.besoinFormulaireTexte &&
       !exercice.besoinFormulaire2CaseACocher &&
        !exercice.besoinFormulaire2Numerique &&
        !exercice.besoinFormulaire2Texte &&
        !exercice.besoinFormulaire3CaseACocher &&
        !exercice.besoinFormulaire3Numerique &&
        !exercice.besoinFormulaire3Texte &&
        !exercice.besoinFormulaire4CaseACocher &&
        !exercice.besoinFormulaire4Numerique &&
        !exercice.besoinFormulaire4Texte
      ) {
        isSettingsVisible = false
      }
    }
    // affectation du zoom pour les figures scratch
    const scratchDivs = divExercice.getElementsByClassName('scratchblocks')
    for (const scratchDiv of scratchDivs) {
      const svgDivs = scratchDiv.getElementsByTagName('svg')
      for (const svg of svgDivs) {
        if (svg.hasAttribute('data-width') === false) {
          const originalWidth = svg.getAttribute('width')
          svg.dataset.width = originalWidth ?? '0'
        }
        if (svg.hasAttribute('data-height') === false) {
          const originalHeight = svg.getAttribute('height')
          svg.dataset.height = originalHeight ?? '0'
        }
        const w =
          Number(svg.getAttribute('data-width')) * Number($globalOptions.z)
        const h =
          Number(svg.getAttribute('data-height')) * Number($globalOptions.z)
        svg.setAttribute('width', String(w))
        svg.setAttribute('height', String(h))
      }
    }
    // Evènement indispensable pour pointCliquable par exemple
    const exercicesAffiches = new window.Event('exercicesAffiches', {
      bubbles: true
    })
    document.dispatchEvent(exercicesAffiches)
  })

  async function newData () {
    if (Object.prototype.hasOwnProperty.call(exercice, 'listeQuestions')) {
      if (isCorrectionVisible && isInteractif) isCorrectionVisible = false
      if (
        exercice !== undefined &&
        typeof exercice?.applyNewSeed === 'function'
      ) {
        exercice.applyNewSeed()
      }
      if (buttonScore) initButtonScore()
      if (
        window.localStorage !== undefined &&
        exercice.id !== undefined &&
        isCorrectionVisible
      ) {
        window.localStorage.setItem(`${exercice.id}|${exercice.seed}`, 'true')
      }
      await updateDisplay()
    }
  }

  async function setAllInteractif () {
    if (exercice?.interactifReady) isInteractif = true
    await updateDisplay()
  }
  async function removeAllInteractif () {
    if (exercice?.interactifReady) isInteractif = false
    await updateDisplay()
  }

  function handleNewSettings (event: CustomEvent) {
    if (event.detail.nbQuestions) {
      exercice.nbQuestions = event.detail.nbQuestions
      $exercicesParams[indiceExercice].nbQuestions = exercice.nbQuestions
    }
    if (event.detail.duration) {
      exercice.duration = event.detail.duration
      $exercicesParams[indiceExercice].duration = exercice.duration
    }
    if (event.detail.sup !== undefined) {
      exercice.sup = event.detail.sup
      $exercicesParams[indiceExercice].sup = mathaleaHandleSup(exercice.sup)
    }
    if (event.detail.sup2 !== undefined) {
      exercice.sup2 = event.detail.sup2
      $exercicesParams[indiceExercice].sup2 = mathaleaHandleSup(exercice.sup2)
    }
    if (event.detail.sup3 !== undefined) {
      exercice.sup3 = event.detail.sup3
      $exercicesParams[indiceExercice].sup3 = mathaleaHandleSup(exercice.sup3)
    }
    if (event.detail.sup4 !== undefined) {
      exercice.sup4 = event.detail.sup4
      $exercicesParams[indiceExercice].sup4 = mathaleaHandleSup(exercice.sup4)
    }
    if (event.detail.alea !== undefined) {
      exercice.seed = event.detail.alea
      $exercicesParams[indiceExercice].alea = exercice.seed
    }
    if (event.detail.correctionDetaillee !== undefined) {
      exercice.correctionDetaillee = event.detail.correctionDetaillee
      $exercicesParams[indiceExercice].cd = exercice.correctionDetaillee
        ? '1'
        : '0'
    }
    if (isExerciceChecked) {
      // Si on change des réglages alors qu'on a déjà une note à l'exercice
      // alors on part sur de nouvelles données ainsi on efface le score et les réponses proposées
      isExerciceChecked = false
      newData()
    } else {
      updateDisplay()
    }
  }

  async function updateDisplay () {
    if (exercice == null) return
    if (
      exercice.seed === undefined &&
      typeof exercice.applyNewSeed === 'function'
    ) {
      exercice.applyNewSeed()
    }
    seedrandom(exercice.seed, { global: true })
    if (exercice.typeExercice === 'simple') {
      mathaleaHandleExerciceSimple(exercice, Boolean(isInteractif))
    }
    exercice.interactif = isInteractif
    if ($exercicesParams[indiceExercice] != null) {
      $exercicesParams[indiceExercice].alea = exercice.seed
      $exercicesParams[indiceExercice].interactif = isInteractif ? '1' : '0'
      $exercicesParams[indiceExercice].cols =
        columnsCount > 1 ? columnsCount : undefined
    }
    exercice.numeroExercice = indiceExercice
    if (
      exercice.typeExercice !== 'simple' &&
      typeof exercice.nouvelleVersion === 'function'
    ) {
      exercice.nouvelleVersion(indiceExercice)
    }
    mathaleaUpdateUrlFromExercicesParams()
    await adjustMathalea2dFiguresWidth()
  }

  function verifExercice () {
    isCorrectionVisible = true
    isExerciceChecked = true
    resultsByExercice.update((l) => {
      const indice = exercice.numeroExercice ?? 0
      const result = {
        ...exerciceInteractif(exercice, divScore, buttonScore),
        indice
      }
      if (result != null) {
        l[indice] = result
      }
      return l
    })
  }

  function initButtonScore () {
    buttonScore.classList.remove(...buttonScore.classList)
    buttonScore.classList.add(
      'inline-flex',
      'px-6',
      'py-2.5',
      'ml-6',
      'bg-aleatex-action',
      'dark:bg-aleatexdark-action',
      'text-aleatex-canvas',
      'dark:text-aleatexdark-canvas',
      'font-medium',
      'text-xs',
      'leading-tight',
      'uppercase',
      'rounded',
      'shadow-md',
      'transform',
      'hover:bg-aleatex-action-lightest',
      'dark:hover:bg-aleatexdark-action-lightest',
      'hover:shadow-lg',
      'focus:bg-aleatex-action-lightest',
      'dark:focus:bg-aleatexdark-action-lightest',
      'focus:shadow-lg',
      'focus:outline-none',
      'focus:ring-0',
      'active:bg-aleatex-action-lightest',
      'dark:active:bg-aleatexdark-action-lightest',
      'active:shadow-lg',
      'transition',
      'duration-150',
      'ease-in-out',
      'checkReponses'
    )
    if (divScore) divScore.innerHTML = ''
  }

  /**
   * Recherche toutes les figures ayant la classe `mathalea2d` et réduit leur largeur à 95% de la valeur
   * maximale du div reperé par l'ID `consigne<X>-0` où `X` est l'indice de l'exercice
   * @param {boolean} initialDimensionsAreNeeded si `true`, les valeurs initiales sont rechargées ()`false` par défaut)
   * @author sylvain
   */
  async function adjustMathalea2dFiguresWidth (
    initialDimensionsAreNeeded: boolean = false
  ) {
    const mathalea2dFigures = document.getElementsByClassName('mathalea2d')
    if (mathalea2dFigures != null) {
      const consigneDiv = document.getElementById(
        'consigne' + indiceExercice + '-0'
      )
      if (mathalea2dFigures.length !== 0) {
        await tick()
        for (let k = 0; k < mathalea2dFigures.length; k++) {
          if (initialDimensionsAreNeeded) {
            // réinitialisation
            const initialWidth = mathalea2dFigures[k].getAttribute(
              'data-width-initiale'
            )
            const initialHeight = mathalea2dFigures[k].getAttribute(
              'data-height-initiale'
            )
            mathalea2dFigures[k].setAttribute('width', initialWidth ?? '0')
            mathalea2dFigures[k].setAttribute('height', initialHeight ?? '0')
            // les éléments des tableaux de variations reviennent à leurs positions initiales
            if (divExercice != null) {
              const eltsInVariationTables =
                divExercice.querySelectorAll<HTMLElement>('[id^="divLatex-"]')
              for (const elt of eltsInVariationTables) {
                const e = elt
                e.style.setProperty('top', e.dataset.top + 'px')
                e.style.setProperty('left', e.dataset.left + 'px')
              }
            }
          }
          if (
            consigneDiv &&
            mathalea2dFigures[k].clientWidth > consigneDiv.clientWidth
          ) {
            // console.log("got figures !!! --> DIV " + consigneDiv.clientWidth + " vs FIG " + mathalea2dFigures[k].clientWidth)
            const coef =
              (consigneDiv.clientWidth * 0.95) /
              mathalea2dFigures[k].clientWidth
            const newFigWidth = consigneDiv.clientWidth * 0.95
            const newFigHeight = mathalea2dFigures[k].clientHeight * coef
            mathalea2dFigures[k].setAttribute('width', newFigWidth.toString())
            mathalea2dFigures[k].setAttribute('height', newFigHeight.toString())
            // console.log("fig" + k + " new dimensions : " + newFigWidth + " x " + newFigHeight)
          }
        }
      }
    }
  }
  // pour recalculer les tailles lors d'un changement de dimension de la fenêtre
  window.onresize = async () => {
    await adjustMathalea2dFiguresWidth(true)
  }  
</script>

<div class="z-0 flex-1" bind:this={divExercice}>
  <HeaderExerciceVueProf    
    {...headerProps}
    on:clickVisible={(event) => {
      isVisible = event.detail.isVisible
    }}
    on:clickSettings={(event) =>
      (isSettingsVisible = event.detail.isSettingsVisible)}
    on:clickCorrection={async (event) => {
      isContentVisible = event.detail.isContentVisible
      isCorrectionVisible = event.detail.isCorrectionVisible
      if (
        window.localStorage !== undefined &&
        exercice.id !== undefined &&
        isCorrectionVisible
      ) {
        window.localStorage.setItem(`${exercice.id}|${exercice.seed}`, 'true')
      }
      if (isInteractif) {
        isInteractif = !isInteractif
        exercice.interactif = isInteractif
        await updateDisplay()
      }
      await adjustMathalea2dFiguresWidth()
    }}
    on:clickInteractif={async (event) => {
      isInteractif = event.detail.isInteractif
      exercice.interactif = isInteractif
      exercicesParams.update((params) => {
        params[indiceExercice].interactif = isInteractif ? '1' : '0'
        return params
      })
      await updateDisplay()
    }}
    on:clickNewData={newData}
    interactifReady={Boolean(
      exercice?.interactifReady &&
        !isCorrectionVisible &&
        headerProps?.interactifReady
    )}
  />

  {#if isVisible}
    <div class="flex flex-col-reverse lg:flex-row">
      <div
        class="flex flex-col justify-start items-start relative {isSettingsVisible
          ? 'w-full lg:w-3/4'
          : 'w-full'} duration-500"
        id="exercice{indiceExercice}"
      >
        <div
          class="print-hidden hidden md:flex flex-row justify-start text-aleatex-struct dark:text-aleatexdark-struct text-xs mt-2 pl-0 md:pl-2"
        >
          <button
            class={columnsCount > 1 ? 'visible' : 'invisible'}
            type="button"
            on:click={() => {
              columnsCount--
              updateDisplay()
            }}
          >
            <i
              class=" text-aleatex-action hover:text-aleatex-action-darkest dark:text-aleatexdark-action dark:hover:text-aleatexdark-action-darkest bx ml-2 bx-xs bx-minus"
            />
          </button>
          <i class="bx ml-1 bx-xs bx-columns" />
          <button
            type="button"
            on:click={() => {
              columnsCount++
              updateDisplay()
            }}
          >
            <i
              class="text-aleatex-action hover:text-aleatex-action-darkest dark:text-aleatexdark-action dark:hover:text-aleatexdark-action-darkest bx ml-1 bx-xs bx-plus"
            />
          </button>
        </div>
        <article
          class="lg:text-base relative"
          style="font-size: {(
            $globalOptions.z || 1
          ).toString()}rem; line-height: calc({$globalOptions.z || 1});"
        >
          {#if typeof exercice.consigne !== 'undefined' && exercice.consigne.length !== 0}
            <div>
              <p
                class=" mt-2 mb-2 ml-2 lg:mx-5 text-aleatex-corpus dark:text-aleatexdark-corpus"
              >
                <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                {@html exercice.consigne}
              </p>
            </div>
          {/if}
          {#if exercice.introduction}
            <div>
              <p
                class="mt-2 mb-2 ml-2 lg:mx-5 text-aleatex-corpus dark:text-aleatexdark-corpus"
              >
                <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                {@html exercice.introduction}
              </p>
            </div>
          {/if}
          <div style="columns: {columnsCount.toString()}" class="mb-5">
            <ul
              class="{exercice.listeQuestions.length === 1 ||
              !exercice.listeAvecNumerotation
                ? 'list-none'
                : 'list-decimal'} w-full list-inside mb-2 mx-2 lg:mx-6 marker:text-aleatex-struct dark:marker:text-aleatexdark-struct marker:font-bold"
            >
              {#each exercice.listeQuestions as item, i (i)}
                <div
                  style="break-inside:avoid"
                  id="consigne{indiceExercice}-{i}"
                  class="container w-full grid grid-cols-1 auto-cols-min gap-1 lg:gap-4 mb-2 lg:mb-4 text-aleatex-corpus dark:text-aleatexdark-corpus"
                >
                  <li
                    id="exercice{indiceExercice}Q{i}"
                    style="line-height: {exercice.spacing || 1}"
                  >
                    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                    {@html mathaleaFormatExercice(item)}
                  </li>
                  {#if isCorrectionVisible}
                    <div
                      class="relative border-l-aleatex-struct dark:border-l-aleatexdark-struct border-l-[3px] text-aleatex-corpus dark:text-aleatexdark-corpus mt-6 lg:mt-2 mb-6 py-2 pl-4"
                      id="correction${indiceExercice}Q${i}"
                    >
                      <div
                        class={exercice.consigneCorrection.length !== 0
                          ? 'container bg-aleatex-canvas dark:bg-aleatexdark-canvas-dark px-4 py-2 mr-2 ml-6 mb-2 font-light relative w-2/3'
                          : 'hidden'}
                      >
                        <div
                          class="{exercice.consigneCorrection.length !== 0
                            ? 'container'
                            : 'hidden'} absolute top-4 -left-4"
                        >
                          <i
                            class="bx bx-bulb scale-200 text-aleatex-warn-dark dark:text-aleatexdark-warn-dark"
                          />
                        </div>
                        <div class="">
                          <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                          {@html exercice.consigneCorrection}
                        </div>
                      </div>
                      <div
                        class="container overflow-x-scroll overflow-y-hidden md:overflow-x-auto py-1"
                        style="line-height: {exercice.spacingCorr ||
                          1}; break-inside:avoid"
                      >
                        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                        {@html mathaleaFormatExercice(
                          exercice.listeCorrections[i]
                        )}
                      </div>
                      <!-- Avant le commit du 28/03/23, il y avait une mise en page plus complexe
                      et cela posait problème au changement des paramètres avec la correction visible -->
                      <!-- <div class="absolute border-aleatex-struct dark:border-aleatexdark-struct top-0 left-0 border-b-[3px] w-10" /> -->
                      <div
                        class="absolute flex flex-row py-[1.5px] px-3 rounded-t-md justify-center items-center -left-[3px] -top-[15px] bg-aleatex-struct dark:bg-aleatexdark-struct font-semibold text-xs text-aleatex-canvas dark:text-aleatexdark-canvas"
                      >
                        Correction
                      </div>
                      <div
                        class="absolute border-aleatex-struct dark:border-aleatexdark-struct bottom-0 left-0 border-b-[3px] w-4"
                      />
                    </div>
                  {/if}
                </div>
              {/each}
            </ul>
          </div>
        </article>
        {#if isInteractif && interactifReady && !isCorrectionVisible && isContentVisible}
          <button
            id="verif{indiceExercice}"
            type="submit"
            on:click={verifExercice}
            bind:this={buttonScore}
            >Vérifier {numberOfAnswerFields > 1
              ? 'les réponses'
              : 'la réponse'}</button
          >
        {/if}
        <div bind:this={divScore} />
      </div>
      <Settings
        {exercice}
        bind:isVisible={isSettingsVisible}
        exerciceIndex={indiceExercice}
        on:settings={handleNewSettings}
      />
    </div>
  {/if}
</div>

<style>
  li {
    break-inside: avoid;
  }
</style>
