<script lang="ts">
  import {
    urlToQRCodeOnWithinImgTag,
    downloadQRCodeImage,
    allowedImageFormats
  } from '../utils/qrCode'
  import { copyQRCodeImageToClipboard } from '../utils/clipboard'
  import FormRadio from '../forms/FormRadio.svelte'
  import type { NumberRange } from '../../lib/types'

  // type FormatCodeRange = Range<0, allowedImageFormats.length>

  export let imageId: string = 'QRImage'
  export let dialogId: string = 'dialogQR'
  export let width: number = 100
  export let format: NumberRange<0, 2> // <-- 2 correspond à allowedImageFormats.length mais passer la variable ne fonctionne pas
  export let tooltipMessage: string = 'My tooltip'
  export let buttonSize: string = 'text-2xl'
  export let buttonIcon: string = 'bx-qr'
  export let buttonSecondIcon: string = ''
  export let classForButton: string = ''
  export let urlAddendum: string = ''
  export let isShort: boolean = false
  export let isEncrypted: boolean = false

  const labelsForFormats: { label: string; value: number }[] = []
  allowedImageFormats.forEach((kind, index) => {
    labelsForFormats.push({ label: kind.name, value: index })
  })
</script>

<!--
    @component
    Bouton pour afficher un modal permettant d'obtenir un QR-Code de l'URL _courante_
    via copie dans le presse-papier ou téléchargement.

    __Paramètres__ :

    * `imageId` : ID de l'image
    * `dialogId` : ID de la dialog box
    * `tooltipMessage` : message affiché au survol
    * `buttonSize` : taille du bouton
    * `buttonIcon` : icone utilisée pour le bouton
    * `buttonSecondIcon` : icone à ajouter
    * `classForButton` : pour ajouter des éléments de positionnement du bouton
    * `width`: largeur du QR-Code
    * `format`: un chiffre correspondant au format de l'image créée
    (basé sur le tableau des formats possible `allowedImageFormats` de `qr-code.js`)
    * `urlAddendum` : chaîne à ajouter à l'URL
    * `isShorten`: l'URL attendue doit-elle être raccourcie ou non.
    * `isEncrypted`: l'URL attendue doit-elle être cryptée ou non.

    __Exemple__ :

    ```tsx
    <ModalForQrCode
        dialogId="QRCodeModal-2"
        imageId="QRCodeCanvas-2"
        tooltipMessage="QR-code du diaporama"
        width={QRCodeWidth}
        format={formatQRCodeIndex}
        buttonSize="text-[100px]"
        classForButton="mx-12 my-2"
        urlAddendum={buildUrlAddendumForEsParam()}
    />
    ```

 -->
<label for={dialogId} class="{classForButton}  hover:cursor-pointer">
  <div class="tooltip tooltip-bottom tooltip-neutral" data-tip={tooltipMessage}>
    <i
      class="relative bx {buttonSize} text-aleatex-action hover:text-aleatex-action-lightest dark:text-aleatexdark-action dark:hover:text-aleatexdark-action-lightest self-center {buttonIcon}"
      on:click={() =>
        urlToQRCodeOnWithinImgTag(
          imageId,
          width,
          format,
          urlAddendum,
          isShort,
          isEncrypted
        )}
      on:keydown={() =>
        urlToQRCodeOnWithinImgTag(
          imageId,
          width,
          format,
          urlAddendum,
          isShort,
          isEncrypted
        )}
      role="button"
      tabindex="0"
    />
    {#if buttonSecondIcon.length !== 0}
      <i
        class="absolute -bottom-1 bg-aleatex-canvas dark:bg-aleatexdark-canvas rounded-full bx {buttonSecondIcon} text-sm -translate-x-3 text-aleatex-warn dark:text-aleatexdark-warn"
      />
    {/if}
  </div>
</label>
<input type="checkbox" id={dialogId} class="modal-toggle" />
<div class="modal">
  <div
    class="modal-box relative z-0 bg-aleatex-canvas dark:bg-aleatexdark-canvas-dark"
  >
    <dialog
      class="rounded-xl z-10 bg-aleatex-canvas text-aleatex-corpus dark:bg-aleatexdark-canvas-dark dark:text-aleatexdark-corpus-light"
      id="{dialogId}-1"
    >
      Le QR-Code est copié dans le presse-papier !
    </dialog>
    <dialog
      class="rounded-xl z-10 bg-aleatex-canvas text-aleatex-corpus dark:bg-aleatexdark-canvas-dark dark:text-aleatexdark-corpus-light"
      id="{dialogId}-2"
    >
      Impossible de copier le QR-Code dans ce navigateur !<br /> Vérifier les permissions.
    </dialog>
    <label
      for={dialogId}
      class="btn absolute right-2 top-2 bg-transparent border-0 active:bg-transparent focus:bg-transparent hover:bg-transparent"
    >
      <i
        class="bx text-3xl bx-x text-aleatex-action hover:text-aleatex-action-lightest dark:text-aleatexdark-action dark:hover:text-aleatexdark-action-lightest text-opacity-60 cursor-pointer"
      />
    </label>
    <h3
      class="text-lg font-bold text-aleatex-struct dark:text-aleatexdark-struct"
    >
      QR-Code du Diaporama
    </h3>
    <p
      class="py-4 text-base font-light text-aleatex-corpus dark:text-aleatexdark-corpus"
    >
      Choisissez de copier l'image ou de la télécharger.
    </p>
    <!-- format QRCode -->
    <div class="flex flex-row items-center justify-start">
      <div
        class="font-semibold text-aleatex-struct-light dark:text-aleatexdark-struct-light"
      >
        Format de l'image
      </div>
      <FormRadio
        orientation="row"
        title="Format image"
        bind:valueSelected={format}
        labelsValues={labelsForFormats}
      />
    </div>
    <!-- taille QR-Code -->
    <div class="flex flex-row items-center justify-start my-4">
      <div
        class="text-aleatex-struct-light dark:text-aleatexdark-struct-light font-semibold"
      >
        Taille du QR-Code
      </div>
      <input
        type="number"
        id="modalqrcode-taille-input"
        min="80"
        max="300"
        bind:value={width}
        class="ml-3 w-20 h-8 text-aleatex-corpus dark:text-aleatexdark-corpus bg-aleatex-canvas dark:bg-aleatexdark-canvas-dark border-1 border-aleatex-action dark:border-aleatexdark-action font-light focus:border-1 focus:border-aleatex-action dark:focus:border-aleatexdark-action focus:outline-0 focus:ring-0 disabled:opacity-30"
        on:change={() =>
          urlToQRCodeOnWithinImgTag(
            imageId,
            width,
            format,
            urlAddendum,
            isShort,
            isEncrypted
          )}
      />
    </div>
    <div class="flex flex-col justify-center">
      <div class="flex flex-row justify-center p-4">
        <!-- <canvas id=id /> -->
        <img id={imageId} alt="QR-Code du diaporama" />
      </div>
      <div class="flex flex-row justify-center pb-6">
        <div
          class="tooltip tooltip-bottom tooltip-neutral"
          data-tip="Copier le QR-Code"
        >
          <button
            type="button"
            id="modalqrcode-copy-button"
            class="mx-6 my-2 text-aleatex-action hover:text-aleatex-action-lightest dark:text-aleatexdark-action dark:hover:text-aleatexdark-action-lightest"
            on:click={() => copyQRCodeImageToClipboard(imageId, dialogId)}
          >
            <i class="bx text-[30px] bx-copy-alt" />
          </button>
        </div>
        <div
          class="tooltip tooltip-bottom tooltip-neutral"
          data-tip="Télécharger le QR-Code"
        >
          <button
            type="button"
            id="modalqrcode-download-button"
            class="mx-6 my-2 text-aleatex-action hover:text-aleatex-action-lightest dark:text-aleatexdark-action dark:hover:text-aleatexdark-action-lightest"
            on:click={() => downloadQRCodeImage(imageId, format)}
          >
            <i class="bx text-[30px] bx-download" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
