<script lang="ts">
  import { exercicesParams, moveExercice } from '../stores/generalStore'
  export let indice: number
  export let indiceLastExercice: number
  function descendre () {
    exercicesParams.update((l) => {
      return moveExercice(l, indice, indice + 1)
    })
  }
</script>

<button
  on:click={descendre}
  class={indice < indiceLastExercice - 1 ? '' : 'hidden md:invisible'}
>
  <i
    class="text-aleatex-action hover:text-aleatex-action-lightest dark:text-aleatexdark-action dark:hover:text-aleatexdark-action-lightest bx bx-down-arrow-circle"
  />
</button>
