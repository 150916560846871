<script lang="ts">
  export let title = 'Mon titre'
  export let icon = 'bxs-file-pdf'
</script>

<div
  class="mr-4 block rounded-lg bg-aleatex-canvas-dark shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-aleatexdark-canvas-dark"
>
  {#if icon.length !== 0}
    <div class="flex justify-center items-center p-4">
      <i
        class="bx {icon} text-[100px] text-aleatex-warn dark:text-aleatexdark-warn"
      ></i>
    </div>
  {/if}
  <div class="py-1 px-6 md:px-16">
    <h5
      class="mb-2 text-3xl font-black leading-tight text-aleatex-struct-light dark:text-aleatexdark-struct-light"
    >
      {title}
    </h5>
    <p
      class="flex flex-col space-y-2 w-full mb-4 text-base font-light text-aleatex-corpus dark:text-aleatexdark-corpus"
    >
      <slot>
        Un texte devrait s'afficher ici. Celui-ci est le texte par défaut.
      </slot>
    </p>

    <div class="flex flex-col justify-center items-center space-y-2">
      {#if $$slots.button1}
        <slot name="button1">
          <button
            type="button"
            class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            Go somewhere
          </button>
        </slot>
      {/if}
      {#if $$slots.button2}
        <slot name="button2">
          <button
            type="button"
            class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            Go somewhere
          </button>
        </slot>
      {/if}
    </div>
  </div>
</div>
