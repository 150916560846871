<script lang="ts">
  export let path: string[] = []
</script>

<div class="flex justify-start items-center text-aleatex-struct dark:text-aleatexdark-struct">
  {#each path as step, i}
    <span>{step}</span>
    {#if i < path.length - 1}
      <span><i class="bx bx-chevron-right translate-y-1" /></span>
    {/if}
  {/each}
</div>
