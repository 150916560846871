<script lang="ts">
  import { globalOptions, darkMode, callerComponent } from '../stores/generalStore'
  import Button from '../forms/Button.svelte'
  import { mathaleaHandleComponentChange } from '../../lib/aleatex'
  import NavBarSubtitle from './NavBarSubtitle.svelte'

  export let title: string = 'aleaTeX'
  export let subtitle: string = ''
  export let subtitleType: 'export' | 'design' = 'export'

  function goToMathalea (paramV: string | undefined) {
    if (paramV !== undefined) {
      mathaleaHandleComponentChange(paramV, $callerComponent)
    }
  }
</script>

<!--
  @component
  Barre de titre pour une section du site positionnée en haut dans la page.
  Contient un titre pour l'endroit du site (MathALÉA, etc.),
  un sous titre pour indiquer la section (export, ), un bouton pour gérer le mode sombre et éventuellement
  un bouton pour fermer (c'est-à-dire revenir à la page d'accueil de la section.)

  ### Paramètres

  * `title` : le titre de la page (`MathALÉA` par défaut)
  * `subtitle` : son sous-titre
  * `subTItleType` : mot indiquant le type de la section pour décider de l'affichage (seulelemnt deux mode `export` et `design`)

  ### Exemple

  ```tsx
    <NavBarV2 subtitle="Création de document" subtitleType="design" />
  ```
 -->

<nav class="p-4 bg-aleatex-canvas dark:bg-aleatexdark-canvas md:h-[120px]">
  <!-- container -->
  <div
    class="flex flex-row justify-between items-start w-full mx-auto md:space-x-6"
  >
    <div class="flex flex-col md:flex-row justify-start space-x-0 md:space-x-2">
      <!-- logo -->
      <div class="">
        <div
          on:click={() => goToMathalea($globalOptions.v)}
          on:keydown={() => goToMathalea($globalOptions.v)}
          role="link"
          tabindex="0"
          class=" relative inline-flex text-3xl md:text-6xl font-logo9 tracking-tighter font-black
          {subtitleType === 'design'
            ? 'text-aleatex-struct dark:text-aleatexdark-struct'
            : 'text-aleatex-action hover:text-aleatex-action-lightest dark:text-aleatexdark-action dark:hover:text-aleatexdark-action-lightest cursor-pointer '}"
        >
          {title}

          <div
            class="absolute -bottom-4 left-1 font-light text-sm text-aleatex-corpus-lightest dark:text-aleatexdark-corpus-lightest"
          >
            <span class="font-light font-sans mr-1 tracking-normal">par</span>
            <a
              href="https://aleatex.mathslozano.fr"
              target="_blank"
              rel="noreferrer"
              class="font-extrabold font-logo9 tracking-tighter text-aleatex-action dark:text-aleatexdark-action hover:text-aleatex-action-lightest dark:hover:text-aleatexdark-action-lightest"
              >SL & CP<sup>2</sup></a
            >
          </div>
        </div>
      </div>
      <NavBarSubtitle {subtitle} type={subtitleType} />
    </div>
    <div class="flex flex-row space-x-4 px-0 pt-2 md:px-4">
      <label
        class="swap swap-rotate text-aleatex-action hover:text-aleatex-action-lightest dark:text-aleatexdark-action dark:hover:text-aleatexdark-action-lightest"
      >
        <!-- this hidden checkbox controls the state -->
        <input
          id="hidden-checkbox-for-darkmode"
          type="checkbox"
          class="invisible"
          bind:checked={$darkMode.isActive}
        />
        <!-- sun icon -->
        <div class="swap-on"><i class="bx bx-sm bx-sun" /></div>
        <!-- moon icon -->
        <div class="swap-off"><i class="bx bx-sm bx-moon" /></div>
      </label>
      <!--  -->
      <Button
        title=""
        icon="bx-x"
        class="text-3xl {subtitleType === 'design' ? 'hidden' : ''}"
        on:click={() => {
          goToMathalea($globalOptions.v)
        }}
      />
    </div>
  </div>
</nav>

<style></style>
