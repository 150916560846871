<script lang="ts">
  import {
    exercicesParams,
    darkMode,
    globalOptions
  } from './stores/generalStore'
  import {
    mathaleaGenerateSeed,
    mathaleaUpdateUrlFromExercicesParams
  } from '../lib/aleatex.js'
  import Footer from './Footer.svelte'
  import NavBar from './header/NavBar.svelte'
  import Button from './forms/Button.svelte'
  import FormRadio from './forms/FormRadio.svelte'
  import { onMount } from 'svelte'
  import ButtonToggle from './forms/ButtonToggle.svelte'
  import ModalActionWithDialog from './modal/ModalActionWithDialog.svelte'
  import ModalForQRCode from './modal/ModalForQRCode.svelte'
  import {
    copyLinkToClipboard,
    copyEmbeddedCodeToClipboard
  } from './utils/clipboard'
  import { buildUrlAddendumForEsParam } from './utils/urls'
  import type { NumericRange } from '../lib/types'

  onMount(() => {
    // mathaleaUpdateUrlFromExercicesParams($exercicesParams)
    handleSeed()
  })

  const formatQRCodeIndex: NumericRange<0, 2> = 0
  const QRCodeWidth = 100

  const availableLinkFormats = {
    clear: {
      toolTipsMessage: 'En clair',
      icon: 'bx-glasses-alt',
      isShort: false,
      isEncrypted: false
    },
    short: {
      toolTipsMessage: 'Raccourci',
      icon: 'bx-move-horizontal',
      isShort: true,
      isEncrypted: false
    },
    crypt: {
      toolTipsMessage: 'Crypté',
      icon: 'bx-lock',
      isShort: false,
      isEncrypted: true
    }
  }

  type LinkFormat = keyof typeof availableLinkFormats
  let currentLinkFormat: LinkFormat = 'clear'

  function handleEleveVueSetUp () {
    let url = document.URL + '&v=eleve'
    url += '&title=' + $globalOptions.title
    url += '&es=' + buildUrlAddendumForEsParam()
    window.open(url, '_blank')?.focus()
  }

  // Gestion de la graine
  let isDataRandom: boolean = false
  function handleSeed () {
    for (const param of $exercicesParams) {
      if (!isDataRandom && param.alea === undefined) {
        param.alea = mathaleaGenerateSeed()
      } else {
        param.alea = undefined
      }
    }
    mathaleaUpdateUrlFromExercicesParams($exercicesParams)
  }
</script>

<main
  class="mb-auto flex flex-col min-h-screen justify-between bg-aleatex-canvas dark:bg-aleatexdark-canvas {$darkMode.isActive
    ? 'dark'
    : ''}"
>
  <NavBar subtitle="Configurer un lien pour les élèves" subtitleType="export" />
  <div class="flex flex-col h-full w-full bg-aleatex-canvas dark:bg-aleatexdark-canvas">
    <div
      class="h-full w-full md:w-2/3 lg:w-3/5 flex flex-col p-4 md:py-10 bg-aleatex-canvas dark:bg-aleatexdark-canvas mx-auto"
    >
      <div
        class="flex flex-col md:flex-row justify-start px-4 py-2 bg-aleatex-canvas dark:bg-aleatexdark-canvas"
      >
        <h3
          class="font-bold text-2xl text-aleatex-struct dark:text-aleatexdark-struct"
        >
          Réglages
        </h3>
      </div>
      <div class="pt-2 pl-2 grid grid-flow-row md:grid-cols-2 gap-4">
        <div class="pb-2">
          <div
            class="pl-2 pb-2 font-bold text-aleatex-struct-light dark:text-aleatexdark-struct-light"
          >
            Titre
          </div>
          <div class="pl-4 flex flex-col">
            <input
              type="text"
              id="config-eleve-titre-input"
              class="w-1/2 text-sm bg-aleatex-canvas dark:bg-aleatexdark-canvas text-aleatex-corpus dark:text-aleatexdark-corpus border border-aleatex-action dark:border-aleatexdark-action font-light focus:border focus:border-aleatex-action dark:focus:border-aleatexdark-action focus:outline-0 focus:ring-0"
              bind:value={$globalOptions.title}
            />
            <div
              class="mt-1 text-aleatex-corpus font-light italic text-xs {$globalOptions.title &&
              $globalOptions.title.length === 0
                ? ''
                : 'invisible'}"
            >
              Pas de bandeau si laissé vide.
            </div>
          </div>
        </div>
        <div class="pb-2">
          <div
            class="pl-2 pb-2 font-bold text-aleatex-struct-light dark:text-aleatexdark-struct-light"
          >
            Présentation
          </div>
          <FormRadio
            title="présentation"
            bind:valueSelected={$globalOptions.presMode}
            labelsValues={[
              { label: 'Tous les exercices sur une page', value: 'liste_exos' },
              {
                label: 'Une page par exercice',
                value: 'un_exo_par_page',
                isDisabled: $exercicesParams.length === 1
              },
              {
                label: 'Toutes les questions sur une page',
                value: 'liste_questions'
              },
              { label: 'Une page par question', value: 'une_question_par_page' }
              // { label: 'Cartes', value: 'cartes' }
            ]}
          />
          <div class="pl-4 pt-2">
            <ButtonToggle
              id="config-eleve-nb-colonnes-toggle"
              isDisabled={$globalOptions.presMode === 'un_exo_par_page' ||
                $globalOptions.presMode === 'une_question_par_page'}
              titles={['Texte sur deux colonnes', 'Texte sur une colonne']}
              bind:value={$globalOptions.twoColumns}
            />
          </div>
        </div>
        <!-- <div class="pb-2">
          <div
            class="pl-2 pb-2 font-bold text-aleatex-struct-light dark:text-aleatexdark-struct-light"
          >
            Interactivité
          </div>
          <FormRadio
            title="Interactif"
            bind:valueSelected={$globalOptions.setInteractive}
            labelsValues={[
              { label: 'Laisser tel quel', value: '2' },
              { label: 'Tout interactif', value: '1' },
              { label: "Pas d'interactivité", value: '0' }
            ]}
          />
          <div class="pl-2 pt-2">
            <ButtonToggle
              id="config-eleve-interactif-permis-toggle"
              isDisabled={$globalOptions.setInteractive === '0'}
              titles={[
                "Les élèves peuvent modifier l'interactivité",
                "Les élèves ne peuvent pas modifier l'interactivité"
              ]}
              bind:value={$globalOptions.isInteractiveFree}
            />
          </div>
          <div class="pl-2 pt-2">
            <ButtonToggle
              id="config-eleve-refaire-toggle"
              isDisabled={$globalOptions.setInteractive === '0'}
              titles={[
                'Les élèves peuvent répondre une seule fois',
                'Les élèves peuvent répondre plusieurs fois'
              ]}
              bind:value={$globalOptions.oneShot}
            />
          </div>
        </div> -->
        <div class="pb-2">
          <div
            class="pl-2 pb-2 font-bold text-aleatex-struct-light dark:text-aleatexdark-struct-light"
          >
            Données
          </div>
          <div
            class="flex justify-start-items-center pl-2 font-light text-sm text-aleatex-corpus-light"
          >
            Tous les élèves auront des pages :
          </div>
          <div class="flex flex-row justify-start items-center px-4">
            <ButtonToggle
              titles={['identiques', 'différentes']}
              bind:value={isDataRandom}
              on:toggle={handleSeed}
            />
          </div>
        </div>
        <div class="pb-2">
          <div
            class="pl-2 pb-2 font-bold text-aleatex-struct-light dark:text-aleatexdark-struct-light"
          >
            Correction
          </div>
          <div class="flex flex-row justify-start items-center px-4">
            <ButtonToggle
              id={'config-eleve-acces-corrections-toggle'}
              titles={['Accès aux corrections', 'Pas de corrections']}
              bind:value={$globalOptions.isSolutionAccessible}
            />
          </div>
        </div>
      </div>
      <div class="pt-4 pb-8 px-4">
        <Button
          on:click={handleEleveVueSetUp}
          class="px-2 py-1 rounded-md"
          title="Visualiser"
        />
      </div>
      <div class="flex flex-row justify-start px-4 py-2">
        <h3
          class="font-bold text-2xl text-aleatex-struct dark:text-aleatexdark-struct"
        >
          Utilisation
        </h3>
      </div>
      <div
        class="flex flex-col md:flex-row justify-start space-x-10 items-start md:items-center px-4"
      >
        <div
          class="text-aleatex-struct-light dark:text-aleatexdark-struct-light font-semibold"
        >
          Format de l'URL
        </div>
        <div class="flex">
          <FormRadio
            title="linkFormat"
            bind:valueSelected={currentLinkFormat}
            labelsValues={[
              { label: 'En clair', value: 'clear' },
              { label: 'Crypté', value: 'crypt' },
              { label: 'Raccourci', value: 'short', isDisabled: true }
            ]}
            orientation="row"
          />
        </div>
      </div>
      <div class="flex flex-row justify-start items-start space-x-10 pt-3 pl-4">
        <div class="flex flex-col items-center px-2">
          <div
            class="text-aleatex-struct-lightest dark:text-aleatexdark-struct-light font-semibold"
          >
            Lien
          </div>
          <div class="my-1">
            <ModalActionWithDialog
              on:display={() =>
                copyLinkToClipboard(
                  'linkCopiedDialog',
                  buildUrlAddendumForEsParam(),
                  availableLinkFormats[currentLinkFormat].isShort,
                  availableLinkFormats[currentLinkFormat].isEncrypted
                )}
              message="Le lien de la fiche élève est copié dans le presse-papier !"
              messageError="Impossible de créer le lien dans le presse-papier !"
              dialogId="linkCopiedDialog"
              tooltipMessage={'Lien ' +
                availableLinkFormats[currentLinkFormat].toolTipsMessage}
              buttonSecondIcon={availableLinkFormats[currentLinkFormat].icon}
            />
          </div>
        </div>
        <div class="flex flex-col justify-center items-center px-2">
          <div
            class="text-aleatex-struct-lightest dark:text-aleatexdark-struct-lightest font-semibold"
          >
            QR-Code
          </div>
          <div class="my-1">
            <ModalForQRCode
              tooltipMessage={'QR-code (lien ' +
                availableLinkFormats[currentLinkFormat].toolTipsMessage +
                ')'}
              width={QRCodeWidth}
              format={formatQRCodeIndex}
              isEncrypted={availableLinkFormats[currentLinkFormat].isEncrypted}
              isShort={availableLinkFormats[currentLinkFormat].isShort}
              urlAddendum={buildUrlAddendumForEsParam()}
              buttonSecondIcon={availableLinkFormats[currentLinkFormat].icon}
            />
          </div>
        </div>
        <div class="flex flex-col justify-center items-center px-2">
          <div
            class="text-aleatex-struct-lightest dark:text-aleatexdark-struct-light font-semibold"
          >
            Embarqué
          </div>
          <div class="my-1">
            <ModalActionWithDialog
              on:display={() =>
                copyEmbeddedCodeToClipboard(
                  'embeddedCodeCopiedDialog',
                  buildUrlAddendumForEsParam(),
                  availableLinkFormats[currentLinkFormat].isShort,
                  availableLinkFormats[currentLinkFormat].isEncrypted
                )}
              message="Le code de la fiche élève est copié dans le presse-papier !"
              messageError="Impossible de créer le code dans le presse-papier !"
              dialogId="embeddedCodeCopiedDialog"
              tooltipMessage={'Code (lien ' +
                availableLinkFormats[currentLinkFormat].toolTipsMessage +
                ')'}
              buttonIcon={'bx-code-alt'}
              buttonSecondIcon={availableLinkFormats[currentLinkFormat].icon}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</main>
