<script lang="ts">
    import {
    callerComponent,
    globalOptions,
  } from './stores/generalStore'
</script>

<footer class="w-full bg-aleatex-canvas dark:bg-aleatexdark-canvas h-12 flex flex-row-reverse justify-center items-center opacity-100 p-4">
  <img class="hidden md:block px-6" style="height: 20px" src="/assets/images/logoLatex.png" alt="Logo LaTeX" />
  <!-- <a
    class="flex flex-row items-center justify-start pr-6 font-thin text-aleatex-action hover:text-aleatex-action-lightest dark:text-aleatexdark-action dark:hover:text-aleatexdark-action-lightest"
    href="https://coopmaths.fr/twitter"
    target="_blank"
  >
    <i class="bx bx-sm bxl-twitter" />
  </a>
  <a
    class="flex flex-row items-center justify-start pr-6 font-thin text-aleatex-action hover:text-aleatex-action-lightest dark:text-aleatexdark-action dark:hover:text-aleatexdark-action-lightest"
    href="https://mathstodon.xyz/@coopmaths"
    target="_blank"
  >
    <i class="bx bx-sm bxl-mastodon" />
  </a> -->
  <a
    class="flex flex-row items-center justify-start pr-6 font-thin text-aleatex-action hover:text-aleatex-action-lightest dark:text-aleatexdark-action dark:hover:text-aleatexdark-action-lightest"
    href="mailto:sebastien.lozano@ac-nancy-metz.fr,chrpoulain@gmail.com?subject=Contact aleaTeX"
  >
    <i class="bx bx-sm bx-envelope" />
  </a>
  <!-- <a
    class="flex flex-row items-center justify-start pr-6 font-thin text-aleatex-action hover:text-aleatex-action-lightest dark:text-aleatexdark-action dark:hover:text-aleatexdark-action-lightest"
    href="aleatexMaintenance.html"
  >
  <i class="bx bx-sm bxl-creative-commons"></i>
    
  </a> -->
  <button
  type="button"
  class="tooltip tooltip-bottom tooltip-neutral flex flex-row items-center justify-start pr-6 font-thin text-aleatex-action hover:text-aleatex-action-lightest dark:text-aleatexdark-action dark:hover:text-aleatexdark-action-lightest"
  data-tip="Licence"
  on:click={() => {
    // $callerComponent = ''
    // globalOptions.update((params) => {
    //   params.v = 'licence'
    //   return params
    // })
    window.location.href='https://aleatex.doc.mathslozano.fr/licences-moteur'
    console.info('appel licence')
  }}
>
<i class="bx bx-sm bxl-creative-commons"></i>
</button>
</footer>
