<script lang="ts">
  import { createEventDispatcher, onDestroy, onMount, tick } from 'svelte'
  import { stringToCriterion } from '../../lib/types/filters'
  import {
    isExerciceItemInReferentiel,
    isTool,
    type ExerciceItemInReferentiel,
    type ResourceAndItsPath,
    type ToolItemInReferentiel,
    type Level
  } from '../../lib/types/referentiels'
  import { exercicesParams, globalOptions } from '../stores/generalStore'
  import {
    allFilters,
    getSelectedFiltersObjects,
    handleUncheckingMutipleFilters,
    filtersHaveChanged
  } from '../stores/filtersStore'
  import type { FilterObject, InterfaceParams } from '../../lib/types'
  import { getUniqueStringBasedOnTimeStamp, debounce } from '../utils/time'
  // import Button from '../forms/Button.svelte'
  import Filtres from './Filtres.svelte'
  import Chip from '../forms/Chip.svelte'
  export let origin: ResourceAndItsPath[]
  export let results: ResourceAndItsPath[] = []
  let searchField: HTMLInputElement
  let inputSearch: string = ''
  let isFiltersVisible: boolean = false
  let selectedFilters: FilterObject<string | Level>[] = []
  const dispatch = createEventDispatcher()
  const timeStamp = getUniqueStringBasedOnTimeStamp()

  // ===================================================================================
  //
  //                                Gestion de la recherche
  //
  // ===================================================================================
  function updateResults (input: string): void {
    if (input.length === 0) {
      results = []
    } else {
      results = [
        ...stringToCriterion(
          input,
          true
        ).meetCriterion(origin)
      ]
    }
    // retirer les doublons de la liste des résultats
    const uniques: ResourceAndItsPath[] = []
    const treatedUuids: string[] = []
    for (const elt of results) {
      if (!treatedUuids.includes(elt.resource.uuid)) {
        treatedUuids.push(elt.resource.uuid)
        uniques.push(elt)
      }
    }
    results = [...uniques]
    // console.log('results')
    // console.log(results)
  }
  // maj de selectedFilters chaque fois que le store `allFilters` change
  const unsubscribeToFiltersStore = allFilters.subscribe(() => {
    selectedFilters = [...getSelectedFiltersObjects()]
  })
  onDestroy(() => {
    unsubscribeToFiltersStore()
  })
  const fetchResults = debounce<typeof updateResults>(updateResults, 500)
  let lastInput: string = ''
  $: {
    // on attend que le champ de recherche ne soit pas vide
    // ou que la chaîne saisie ne commence pas par une apostrophe ou un guillemet
    if (
      inputSearch.length !== 0 &&
      inputSearch.replace(/^[\s"']/, '').length !== 0
    ) {
      if (inputSearch !== lastInput) {
        // console.log('last: ' + lastInput + ' / current: ' + inputSearch + ' -> je remplis !')
        lastInput = inputSearch
        fetchResults(inputSearch)
      }
      if ($filtersHaveChanged) {
        $filtersHaveChanged = false
        fetchResults(inputSearch)
      }
    } else {
      // console.log('last: ' + lastInput + ' / current: ' + inputSearch + ' -> je vide !')
      results = []
    }
    results = results
  }
  // ===================================================================================
  //
  //                                Gestion du clavier
  //
  // ===================================================================================
  let isInputFocused = false
  function onFocusInput () {
    isInputFocused = true
  }
  function onBlurInput () {
    isInputFocused = false
  }
  // ================== Gestion du Ctrl + K et Enter  ==============================
  // Pour la gestion du clavier voir
  // source : https://svelte.dev/repl/48bd3726b74c4329a186838ce645099b?version=3.46.4
  let isCtrlDown: boolean = false
  let isKDown: boolean = false
  let isEnterDown: boolean = false
  /**
   * Si Ctrl+K afficher le champ de recherche avec focus
   */
  function onCtrklK () {
    getFocusOnSearchInput()
  }
  /**
   * Recherche si la chaîne de l'input correspond à une ID de la liste des résultats.
   * @returns {ExerciceItemInReferentiel|ToolItemInReferentiel|null} renvoie l'exercice trouvé ou `null`
   */
  function matchOnResultsList (
    idString: string
  ): ExerciceItemInReferentiel | ToolItemInReferentiel | null {
    for (const result of results) {
      if (
        isExerciceItemInReferentiel(result.resource) ||
        isTool(result.resource)
      ) {
        if (idString === result.resource.id) {
          return result.resource
        }
      }
    }
    return null
  }
  /**
   * Si Entrée et qu'un seul exercice matche alors on ajoute l'exercice à la liste
   */
  function onEnterDown () {
    const matchingResource = matchOnResultsList(inputSearch)
    if (matchingResource !== null) {
      const newExercise: InterfaceParams = {
        uuid: matchingResource.uuid
      }
      if ($globalOptions.recorder === 'capytale') {
        newExercise.interactif = '1'
      }
      exercicesParams.update((list) => [...list, newExercise])
    }
  }
  /**
   *
   * @param event
   */
  function onKeyDown (event: KeyboardEvent) {
    if (event.repeat) return
    switch (event.key) {
      case 'Control':
        isCtrlDown = true
        event.preventDefault()
        break
      case 'k':
        isKDown = true
        // event.preventDefault() // Empêchait l'utilisation de la touche k
        break
      case 'Enter':
        if (isInputFocused) {
          isEnterDown = true
        }
        event.preventDefault()
        break
    }
    if (isCtrlDown && isKDown) {
      onCtrklK()
    }
    if (isEnterDown) {
      onEnterDown()
    }
  }

  function onKeyUp (event: KeyboardEvent) {
    switch (event.key) {
      case 'Control':
        isCtrlDown = false
        event.preventDefault()
        break
      case 'k':
        isKDown = false
        // event.preventDefault()
        break
      case 'Enter':
        isEnterDown = false
        break
    }
  }
  const getFocusOnSearchInput = async () => {
    await tick()
    searchField.focus()
  }
  /**
   * Permet d'afficher séquentiellement une liste de chaînes de caractères
   * dans un champ de recherche à la place du placeholder
   * @see https://stackoverflow.com/a/57903237/6625987
   * @param selectorTarget
   * @param textList
   * @param placeholder
   * @param i
   * @param textListI
   * @param delayMs
   */
  function typeWriter (
    selectorTarget: string,
    textList: string[],
    placeholder = false,
    i = 0,
    textListI = 0,
    delayMs = 100
  ) {
    if ($exercicesParams.length === 0) {
      if (!i) {
        if (placeholder) {
          const inputDiv = document.querySelector(
            selectorTarget
          ) as HTMLInputElement
          if (inputDiv) {
            inputDiv.placeholder = ''
          }
        } else {
          const inputDiv = document.querySelector(
            selectorTarget
          ) as HTMLInputElement
          if (inputDiv) {
            inputDiv.innerHTML = ''
          }
        }
      }
      const txt = textList[textListI]
      if (i < txt.length) {
        if (placeholder) {
          const inputDiv = document.querySelector(
            selectorTarget
          ) as HTMLInputElement
          if (inputDiv) {
            inputDiv.placeholder += txt.charAt(i)
          }
        } else {
          const inputDiv = document.querySelector(
            selectorTarget
          ) as HTMLInputElement
          if (inputDiv) {
            inputDiv.innerHTML += txt.charAt(i)
          }
        }
        i++
        setTimeout(
          typeWriter,
          delayMs,
          selectorTarget,
          textList,
          placeholder,
          i,
          textListI
        )
      } else {
        textListI++
        if (typeof textList[textListI] === 'undefined') {
          setTimeout(
            typeWriter,
            delayMs * 7,
            selectorTarget,
            textList,
            placeholder
          )
        } else {
          i = 0
          setTimeout(
            typeWriter,
            delayMs * 7,
            selectorTarget,
            textList,
            placeholder,
            i,
            textListI
          )
        }
      }
    } else {
      const inputDiv = document.querySelector(
        selectorTarget
      ) as HTMLInputElement
      if (inputDiv) {
        inputDiv.placeholder = '🔍 Thème, identifiant...'
      }
    }
  }

  const inputSearchSamples = [
    'thales 🔍',
    '3e arithm 🔍',
    'dnb+3e proba 🔍',
    'dnbpro metropole 🔍',
    'crpe 2022+2024 algo 🔍'
  ]

  onMount(() => {
    typeWriter(`#searchInputField-${timeStamp}`, inputSearchSamples, true)
  })
</script>

<!--
  @component
  Champ de texte pour recherche d'exercices
  ### Paramètres
  - **origin** (_ResourceAndItsPath[]_) : le référentiel à rechercher (déplier dans un tableau)
  - **result** (_ResourceAndItsPath[]_) : la liste des entrées correspondant au texte dans le champ de recherche
 -->
<svelte:window on:keydown={onKeyDown} on:keyup={onKeyUp} />
<div class="flex flex-col justify-start items-center">
  <div class="relative flex flex-col w-full">
    <input
      type="search"
      id="searchInputField-{timeStamp}"
      class="w-full border border-aleatex-action dark:border-aleatexdark-action focus:border-aleatex-action-lightest dark:focus:border-aleatexdark-action-lightest focus:outline-0 focus:ring-0 focus:border-1 bg-aleatex-canvas-dark dark:bg-aleatexdark-canvas-dark text-aleatex-corpus-light dark:text-aleatexdark-corpus-light text-sm placeholder-aleatex-corpus-lightest dark:placeholder-aleatexdark-corpus-lightest placeholder:italic placeholder-opacity-50"
      placeholder="🔍 Thème, identifiant..."
      bind:value={inputSearch}
      bind:this={searchField}
      on:focus={onFocusInput}
      on:blur={onBlurInput}
      autocomplete="off"
      autocorrect="off"
      name="”notASearchField”"
    />
    <!-- Invite pour presser Entrée lors d'un match input = ID d'exo -->
    <div
      class="absolute -bottom-6 {matchOnResultsList(inputSearch) !== null &&
      isInputFocused
        ? 'flex'
        : 'hidden'} items-center pl-1 italic font-extralight text-xs text-aleatex-corpus-lightest dark:text-aleatexdark-corpus-lightest"
    >
      Presser <span class="font-normal mx-1">Entrée</span> pour ajouter l'exercice
    </div>
    <!-- Bouton pour effacer l'input de recherche -->
    <!-- <Button
      title=""
      icon="bxs-tag-x"
      class="absolute right-2 top-1 text-2xl"
      isDisabled={inputSearch.length === 0}
      on:click={() => {
        inputSearch = ''
      }}
    /> -->
    <!-- Bouton pour afficher les filtres -->
    <!-- <button
      type="button"
      class="absolute right-2 -bottom-6 text-sm text-aleatex-action dark:text-aleatexdark-action hover:text-aleatex-action-lightest hover:dark:text-aleatexdark-action-lightest"
      on:click={() => {
        isFiltersVisible = !isFiltersVisible
      }}
    >
      Filtrer les exercices <i class="bx bx-filter-alt" />
    </button> -->
  </div>
  <!-- Chips des filtres -->
  <!-- <div
    class={selectedFilters.length === 0
      ? 'hidden'
      : 'flex w-full flex-row flex-wrap justify-start text-sm mt-6 leading-tight'}
  >
    {#each selectedFilters as filter}
      <Chip
        text={filter.content.title}
        textColor="canvas"
        bgColor="struct"
        isVisible={true}
        on:action={() => {
          $allFilters[filter.type][filter.key].isSelected = false
          handleUncheckingMutipleFilters(filter.key)
          dispatch('filters-change')
          $filtersHaveChanged = true
        }}
      />
    {/each}
  </div> -->
  <!-- Filtres -->
  <!-- <div class={isFiltersVisible ? 'flex flex-col w-full mt-4' : 'hidden'}> -->
    <!-- <Filtres class="mt-2" filterType="knowledges" on:filters-change /> -->
    <!-- <Filtres class="mt-2" filterType="levels" on:filters-change /> -->
    <!-- <Filtres class="mt-2" filterType="specs" on:filters-change /> -->
    <!-- <Filtres class="mt-2" filterType="types" on:filters-change /> -->
  <!-- </div> -->
</div>
